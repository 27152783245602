import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
const styles = require("../styles/richText.module.scss");

const Page = ({
  data: {
    strapiNotesToEditors: { content, heading, metaData },
  },
}) => {
  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
      }}
    >
      <div className={styles.copyWrap}>
        <h1 className={`${styles.header} h2 `}>{heading}</h1>
        <div className={styles.content}>
          {content.map((component) => {
            const { strapi_component, id } = component;
            switch (strapi_component) {
              case "simple.coloured-copy-block":
                const { copy, backgroundColor } = component;

                return (
                  <div
                    key={id}
                    className={`${styles.simpleColouredCopyBlock} bkg-${backgroundColor}`}
                  >
                    {copy ? parse(copy) : null}
                  </div>
                );

              case "simple.rich-text-area":
                const { text } = component;
                return (
                  <div className={styles.textBlock} key={id}>
                    {text ? parse(text) : null}
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiNotesToEditors {
      content
      heading

      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;

export default Page;
